<template>
  <page title="机构、人员">
    <div class="zandiv">
      <commonbanner></commonbanner>
      <div class="container">
        <div class="subtitle">
          <h2>机构、人员</h2>
          <hr class="underline">
        </div>
        <div class="mt-5 mechanism_list">
          <a-tabs default-active-key="org" :animated="false" class="tabs custom-tabs">
            <a-tab-pane key="org" tab="机构">
              <div class="mechanism_list">
                <div class="mechanism_list">
                  <div class="tabs">
                    <div class="screen_box mt-5">
                      <div>
                        <div class="screen_item">
                          <Collection name="serve_org_type" v-slot="{ collection }">
                            <h2 class="screen_title pattern">机构类型:</h2>
                            <p
                              class="select_item"
                              :class="{ active: providerQuery.provider_type === '' }"
                              @click="remove('provider_type')"
                            >不限</p>
                            <p
                              class="select_item"
                              v-for="item in collection"
                              :class="{ active: item.item_value === providerQuery.provider_type }"
                              :key="item.item_value"
                              @click="
            setprovider_type('provider_type', item.item_value)
          "
                            >{{ item.item_name }}</p>
                          </Collection>
                        </div>
                      </div>
                      <City @change="handleCityChange"></City>
                    </div>
                  </div>
                </div>
                <Collection
                  name="provider"
                  :page="provider.current"
                  :query="{
                      city: providerQuery.city,
                      area: providerQuery.area,
                      provider_type: providerQuery.provider_type,
                      provider_name: providerQuery.provider_name
                    }"
                  v-slot="{ collection, count }"
                >
                  <a-row :gutter="[16, 16]" class="mt-5">
                    <a-col :span="6" v-for="item in collection" :key="item.provider_id">
                      <Org :post="item"></Org>
                    </a-col>
                  </a-row>

                  <a-pagination
                    class="mb-5"
                    show-quick-jumper
                    :current="provider.current"
                    :defaultPageSize="provider.defaultPageSize"
                    :total="count"
                    @change="onChange"
                  />
                </Collection>
              </div>
            </a-tab-pane>
            <a-tab-pane key="person" tab="机构人员">
              <div class="mechanism_list">
                <div class="mechanism_list">
                  <div class="tabs">
                    <div class="screen_box mt-5">
                      <div>
                        <div class="screen_item">
                          <Collection name="person_type" v-slot="{ collection }">
                            <h2 class="screen_title pattern">人员类型:</h2>
                            <p
                              class="select_item"
                              :class="{ active: providerQuery.person_type === '' }"
                              @click="remove('person_type')"
                            >不限</p>
                            <p
                              class="select_item"
                              v-for="item in collection"
                              :class="{ active: item.item_value === providerQuery.person_type }"
                              :key="item.item_value"
                              @click="
            setperson_type('person_type', item.item_value)
          "
                            >{{ item.item_name }}</p>
                          </Collection>
                        </div>
                      </div>
                      <City @change="handleCityChange"></City>
                    </div>
                  </div>
                </div>
                <Collection
                  name="person"
                  :page="person.current"
                  :query="{
                      city: providerQuery.city,
                      area: providerQuery.area,
                      provider_name: providerQuery.person_name,
                      person_type: providerQuery.person_type
                    }"
                  v-slot="{ collection, count }"
                >
                  <a-row :gutter="[16, 16]" class="mt-5">
                    <a-col :span="6" v-for="item in collection" :key="item.person_id">
                      <Person :post="item"></Person>
                    </a-col>
                  </a-row>

                  <a-pagination
                    class="mb-5"
                    show-quick-jumper
                    :current="person.current"
                    :defaultPageSize="person.defaultPageSize"
                    :total="count"
                    @change="onChange"
                  />
                </Collection>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
    </div>
  </page>
</template>
<script>
import Org from '@/components/business/Org'
import City from '@/components/business/City'
import Collection from '@/components/Collection'
import Commonbanner from '@/components/base/Commonbanner'
import Person from '@/components/business/Person'
export default {
  components: {
    Commonbanner,
    Collection,
    Org,
    City,
    Person
  },
  data () {
    return {
      provider: {
        current: 1,
        defaultPageSize: 12
      },
      person: {
        current: 1,
        defaultPageSize: 12
      },
      providerQuery: {
        city: '',
        area: '',
        provider_name: '',
        person_name: '',
        provider_type: '',
        person_type: ''
      }
    }
  },
  methods: {
    onChange (page, pageSize) {
      this.provider.current = page
      this.person.current = page
    },
    setprovider_type (key, name) {
      this.providerQuery.provider_type = name
    },
    setperson_type (key, name) {
      this.providerQuery.person_type = name
    },
    handleCityChange (obj) {
      this.providerQuery.city = obj.city
      this.providerQuery.area = obj.area
    },
    remove (key) {
      this.providerQuery[key] = ''
    }
  }
}
</script>
<style scoped src="../../assets/css/zan.css"></style>
